import React from 'react';
import { red } from '@mui/material/colors';
import AlertButton from './AlertButton';

const NEGATIVE_SURPLUS_ALERT_TOOLTIP_TEXT = 'Tienes una deuda pendiente: Mora acumulada supera los excedentes retenidos, cobraremos la diferencia';

const NegativeSurplusAlertButton = () => (
  <AlertButton
    id="alert-negative-surplus-button"
    tooltipText={NEGATIVE_SURPLUS_ALERT_TOOLTIP_TEXT}
    openImmediately
    color={red[500]}
  />
);

export default NegativeSurplusAlertButton;
