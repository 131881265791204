import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Error from '@mui/icons-material/Error';
import PropTypes from 'prop-types';

const AlertButton = ({
  id, tooltipText, tooltipPlacement, onClickFunction, openImmediately, color,
}) => {
  const [open, setOpen] = useState(false);

  // El tooltip del warning aparece abierto por 3.5 segundos
  useEffect(() => {
    if (openImmediately) {
      const openTimer = setTimeout(() => {
        setOpen(true);
      }, 500);
      const closeTimer = setTimeout(() => {
        setOpen(false);
      }, 3500);
      return () => {
        clearTimeout(openTimer);
        clearTimeout(closeTimer);
      };
    }
    return () => {};
  }, [openImmediately]);

  return (
    <Tooltip title={tooltipText} placement={tooltipPlacement} open={open}>
      <IconButton
        id={id}
        variant="contained"
        size="small"
        sx={{
          width: 20,
          height: 20,
          display: 'flex',
          color,
        }}
        onClick={onClickFunction}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <Error size="medium" fontSize="medium" />
      </IconButton>
    </Tooltip>
  );
};

AlertButton.propTypes = {
  id: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  onClickFunction: PropTypes.func,
  openImmediately: PropTypes.bool,
  color: PropTypes.string,
  tooltipPlacement: PropTypes.string,
};

AlertButton.defaultProps = {
  onClickFunction: () => {},
  openImmediately: false,
  color: 'error',
  tooltipPlacement: 'right',
};

export default AlertButton;
