import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Event from '@mui/icons-material/Event';
import ShoppingCartOutlined from '@mui/icons-material/ShoppingCartOutlined';
import { shoppingCartShowQuery } from '../../apollo/cache/localQuery';
import { shoppingCartShow } from '../../apollo/reactive-variables/shopping-cart';
import { formatMoney, toggleCart } from '../../helpers';
import { useShoppingCart, useIsMobile } from '../../hooks';
import { IconButtonFilled } from '../buttons';
import { IconTooltip } from '../tooltips';

const CART_TYPE_MAPPER = {
  'available-offers': 'facturas',
  'available-factoring': 'facturas',
  ordering: 'OC',
};

const ShoppingCartInfo = ({ title, value, loading }) => (
  <Stack direction="column" spacing={1}>
    <Typography variant="h6" fontWeight={600}>
      {title}:
    </Typography>
    {loading ? <Skeleton variant="text" /> : <Typography variant="h6">{value}</Typography>}
  </Stack>
);

ShoppingCartInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool.isRequired,
};

ShoppingCartInfo.defaultProps = {
  value: null,
};

const ShoppingCartPreview = ({ cartType }) => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const { shoppingCartData, disabledShowCart, loading } = useShoppingCart({ cartType });
  const { data: { showShoppingCart } } = useQuery(shoppingCartShowQuery);

  const handleOnClick = useCallback(() => {
    shoppingCartShow(!showShoppingCart); /* toggle menu cart (app) */
    toggleCart(cartType); /* toggle drawer cart (factoring-widget) */
  }, [showShoppingCart]);

  const previewInfo = useMemo(
    () => [
      { title: `Cantidad ${CART_TYPE_MAPPER[cartType]}`, value: shoppingCartData?.invoiceCount },
      {
        title: `Monto total ${CART_TYPE_MAPPER[cartType]}`,
        value: `${formatMoney(shoppingCartData?.totalAmount, true)}`,
      },
    ],
    [cartType, shoppingCartData],
  );

  return (
    <Stack
      width="100%"
      borderRadius={2}
      py={2.5}
      px={4}
      marginBottom={0.5}
      direction="row"
      bgcolor="primary.light"
      justifyContent="space-between"
      spacing={2}
      alignItems="center"
      sx={{
        border: 3,
        borderColor: 'primary.main',
        width: '100%',
      }}
    >
      <Stack direction="row" spacing={isMobile ? 1.5 : 5} justifyContent="flex-start">
        {previewInfo.map(({ title, value }) => (
          <ShoppingCartInfo key={title} title={title} value={value} loading={loading} />
        ))}
      </Stack>
      <IconTooltip
        icon={<Event />}
        text="Recuerda ingresar las fechas de pago"
        disabledHover={!disabledShowCart}
      >
        {isMobile ? (
          <IconButtonFilled
            id="preview-show-cart-mobile"
            icon={<ShoppingCartOutlined />}
            handleOnClick={handleOnClick}
            disabled={disabledShowCart}
            borderRadius={2}
          />
        ) : (
          <Button
            id="preview-show-cart"
            startIcon={<ShoppingCartOutlined />}
            variant="contained"
            size="small"
            onClick={handleOnClick}
            disabled={disabledShowCart}
            sx={{ boxShadow: 0 }}
          >
            {cartType === 'available-offers' ? t('offer action') : 'Cotizar'}
          </Button>
        )}
      </IconTooltip>
    </Stack>
  );
};

ShoppingCartPreview.propTypes = {
  cartType: PropTypes.string.isRequired,
};

export default ShoppingCartPreview;
