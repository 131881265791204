import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/styles';

const LoadingIconButton = styled(LoadingButton)(
  () => ({
    minWidth: 0,
    borderRadius: 10000,
  }),
);

export default LoadingIconButton;
