import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import OpenInNew from '@mui/icons-material/OpenInNew';
import CompanyProfileDialog from '../dialogs/CompanyProfileDialog';
import { useBooleanState } from '../../hooks';

const CompanyProfileButton = ({ masterEntityId }) => {
  const [openProfile, toggleProfile] = useBooleanState(false);
  return (
    <>
      <Button
        onClick={toggleProfile}
        color="primary"
        variant="contained"
        startIcon={<OpenInNew />}
        sx={{ m: 0.3, mt: 1, height: 25, width: '100%', justifyContent: 'flex-start' }}
      >
        <Typography>Abrir ficha empresa</Typography>
      </Button>
      <CompanyProfileDialog
        masterEntityId={masterEntityId}
        open={openProfile}
        setOpen={toggleProfile}
      />
    </>
  );
};

CompanyProfileButton.propTypes = {
  masterEntityId: PropTypes.number.isRequired,
};

export default CompanyProfileButton;
