import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

const StatisticCard = ({ children }) => (
  <Card sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
    <CardContent sx={{ flex: 1, '&:last-child': { paddingY: 1, paddingX: 2 } }}>
      <Stack sx={{ height: '100%' }} spacing={2}>
        {children}
      </Stack>
    </CardContent>
  </Card>
);

export default StatisticCard;

StatisticCard.propTypes = {
  children: PropTypes.node.isRequired,
};
