import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useBooleanState } from '../../hooks';
import { UploadDocumentsManager } from '../inputs';

const UploadDocumentButton = ({ invoiceId, showTitle, ...props }) => {
  const [open, toggleOpen] = useBooleanState(false);
  const component = useMemo(() => (
    <>
      <Tooltip title="Adjuntar archivos">
        <IconButton
          onClick={toggleOpen}
          size="large"
          id="openUploadDocuments"
          disableRipple
        >
          <UploadFileRoundedIcon />
          {showTitle && (
          <Typography
            {...props}
          >
            Documentos
          </Typography>
          )}
        </IconButton>
      </Tooltip>
      <UploadDocumentsManager
        open={open}
        handleClose={toggleOpen}
        invoiceId={invoiceId}
      />
    </>
  ), [showTitle, invoiceId, open]);
  return component;
};

UploadDocumentButton.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
};

UploadDocumentButton.defaultProps = {
  showTitle: false,
};

export default UploadDocumentButton;
