import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import ProgressiveImage from 'react-progressive-graceful-image';

const ProgressiveImageBox = ({ src, alt, ...props }) => {
  if (!src) return <Typography variant="h5">{alt}</Typography>;
  return (
    <ProgressiveImage src={src} placeholder="">
      {(boxSrc, loading) => {
        if (loading) {
          return <CircularProgress color="gray" />;
        }
        return (
          <Box
            component="img"
            sx={{
              width: { xs: '100%', md: '60%' },
              maxWidth: 950,
              height: 'auto',
            }}
            {...props}
            src={boxSrc}
            alt={alt}
          />
        );
      }}
    </ProgressiveImage>
  );
};

ProgressiveImageBox.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
};

ProgressiveImageBox.defaultProps = {
  src: null,
};

export default ProgressiveImageBox;
