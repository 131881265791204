import React from 'react';
import PropTypes from 'prop-types';
import Cached from '@mui/icons-material/Cached';
import { useLastRefreshDate } from '../../hooks';
import LastUpdatedDate from '../text/LastUpdatedDate';
import LoadingIconButton from './LoadingIconButton';

const RefreshIconButton = ({ refetch, loading }) => {
  const [lastUpdated, refreshDateHandler] = useLastRefreshDate();
  const refetchHandler = () => {
    refetch();
    refreshDateHandler();
  };

  return (
    <LoadingIconButton
      onClick={refetchHandler}
      sx={{ mr: { xs: '0', md: 'auto', minWidth: 0, borderRadius: 10000 } }}
      loading={loading}
      color="gray"
    >
      <Cached sx={{ display: { xs: 'none', md: 'inherit' } }} />
      <LastUpdatedDate lastUpdated={lastUpdated} />
    </LoadingIconButton>
  );
};

RefreshIconButton.propTypes = {
  refetch: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default RefreshIconButton;
