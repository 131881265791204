import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import DocumentChip from '../cells/DocumentsChip';
import { useGetInvoice } from '../../hooks';
import { INVOICE_ATTACHABLE_DOCUMENTS } from '../../graphql';
import { documentToAttachableList } from '../../helpers';

const AttachedDocuments = ({ invoiceId }) => {
  const invoice = useGetInvoice(invoiceId, INVOICE_ATTACHABLE_DOCUMENTS);
  const attachableList = useMemo(
    () => (invoice ? documentToAttachableList(invoice) : null),
    [invoice],
  );

  const lastDocuments = useMemo(() => {
    if (!attachableList) return [];

    const lastDocumentsMap = new Map();

    attachableList.forEach((document) => {
      lastDocumentsMap.set(document.label, document);
    });

    return Array.from(lastDocumentsMap.values());
  }, [attachableList]);

  const component = useMemo(() => {
    if (!invoice) return <Typography width="100%" justifyContent="center" align="center" sx={{ paddingTop: 2, fontWeight: 50 }}>Cargando</Typography>;
    if (!lastDocuments.length) return <Typography width="100%" justifyContent="center" align="center" sx={{ paddingTop: 2, fontWeight: 50 }}>No hay documentos cargados</Typography>;
    return (
      lastDocuments.map((attachableFile) => (
        <DocumentChip
          document={attachableFile}
          url={attachableFile.url}
          key={attachableFile.id}
        />
      ))
    );
  }, [invoice, attachableList]);
  return (
    <Grid container direction="row">
      {component}
    </Grid>
  );
};

AttachedDocuments.propTypes = {
  invoiceId: PropTypes.string.isRequired,
};

export default AttachedDocuments;
