import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const StickyButton = ({ button }) => {
  return (
    <Box
      sx={{
        position: 'sticky',
        bottom: 0,
        p: 2,
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        right: 0,
      }}
    >
      {button}
    </Box>
  );
};

StickyButton.propTypes = {
  button: PropTypes.element.isRequired,
};

export default StickyButton;
