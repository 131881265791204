import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DrawerHeader from '../drawer/DrawerHeader';
import { ActionDrawerCell } from '../cells';
import { InvoiceType } from '../../propTypes';
import UserActions from '../drawer/UserActions';

const CollectionManagerActionButton = ({ invoice }) => (
  <IconButton
    disabled={invoice.collectionManager === null}
  >
    <ActionDrawerCell
      title={`Gestiones de cobranza factura ${invoice.folio}`}
      actions={invoice.collectionManager?.actions.filter((action) => action.actionType !== 'OTHER') ?? []}
      headerComponent={DrawerHeader}
      contentComponent={UserActions}
      drawerId={invoice.folio}
    />
    <Typography variant="h2" sx={{ ml: '5px', display: { xs: 'none', sm: 'inline', md: 'inline', lg: 'inline' } }}>Cobranza</Typography>
  </IconButton>

);

CollectionManagerActionButton.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default CollectionManagerActionButton;
