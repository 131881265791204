import React from 'react';
import PropTypes from 'prop-types';
import { Wave } from '../../assets';
import FullHeightBox from './FullHeightBox';

const WaveFullHeightBox = ({ sx, ...props }) => (
  <FullHeightBox
    {...props}
    sx={{
      backgroundImage: {
        xs: 'none',
        md: `url(${Wave})`,
        width: 'auto',
      },
      backgroundPosition: 'bottom',
      minHeight: '100vh',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      ...props.sx,
    }}
  />
);

WaveFullHeightBox.propTypes = {
  sx: PropTypes.shape(),
};
WaveFullHeightBox.defaultProps = {
  sx: {},
};

export default WaveFullHeightBox;
