import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import LoadingIconButton from './LoadingIconButton';
import { DownloadExcelIcon } from '../icons';

const DownloadExcelIconButton = ({ downloadFunction, loading, disabled, title, ...props }) => (
  <Tooltip title={title}>
    <span {...props}>
      <LoadingIconButton
        onClick={downloadFunction}
        loading={loading}
        size="small"
        color="excel"
        id="export-excel"
        disabled={disabled}
        sx={{ height: '28px' }}
      >
        <DownloadExcelIcon />
      </LoadingIconButton>
    </span>
  </Tooltip>
);

DownloadExcelIconButton.propTypes = {
  downloadFunction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
};

DownloadExcelIconButton.defaultProps = {
  disabled: false,
  title: '',
};

export default DownloadExcelIconButton;
