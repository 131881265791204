import React from 'react';
import Portal from '@mui/material/Portal';
import styled from '@mui/material/styles/styled';
import { LoadingButton } from '@mui/lab';

const ButtonWrapper = styled(
  LoadingButton,
)(() => ({
  position: 'fixed',
  bottom: 15,
  margin: '0 auto',
  minWidth: 250,
  left: '50%',
  transform: 'translateX(-50%)',
}));

const BottomConfirmAction = (props) => (
  <Portal>
    <ButtonWrapper {...props} />
  </Portal>
);
BottomConfirmAction.propTypes = {
  ...LoadingButton.propTypes,
};
export default BottomConfirmAction;
