import React from 'react';
import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';
import IconButton from '@mui/material/IconButton';

const rotation = 10;

const myKeyframe = keyframes`
    0 %  { transform: translate(1px, 1px)   rotate(0deg)    },
    10%  { transform: translate(-1px, -2px) rotate(-${rotation}deg);  },
    20%  { transform: translate(-3px, 0px)  rotate(${rotation}deg);   },
    30%  { transform: translate(3px, 2px)   rotate(0deg);   },
    40%  { transform: translate(1px, -1px)  rotate(${rotation}deg);   },
    50%  { transform: translate(-1px, 2px)  rotate(-${rotation}deg);  },
    60%  { transform: translate(-3px, 1px)  rotate(0deg);   },
    70%  { transform: translate(3px, 1px)   rotate(-${rotation}deg);  },
    80%  { transform: translate(-1px, -1px) rotate(${rotation}deg);   },
    90%  { transform: translate(1px, 2px)   rotate(0deg);   },
    100% { transform: translate(1px, -2px)  rotate(-${rotation}deg);  }
`;

const NavbarButton = React.forwardRef(({ children, onClick, animation, ...props }, ref) => (
  <IconButton
    ref={ref}
    sx={{
      p: 0.5,
      bgcolor: 'primary.main',
      color: 'white',
      animation: animation ? `${myKeyframe} 1s infinite ease` : null,
      '&:hover': {
        bgcolor: 'primary.main',
        color: 'background.light',
        opacity: 0.8,
      },
    }}
    onClick={onClick}
    size="large"
    {...props}
  >
    {children}
  </IconButton>
));
NavbarButton.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  animation: PropTypes.bool,
};
NavbarButton.defaultProps = {
  onClick: () => {},
  animation: false,
};

export default NavbarButton;
