import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const LinkButton = ({ to, tooltipTitle, label }) => (
  <Tooltip title={tooltipTitle} placement="top">
    <Button
      id="invoice-profile"
      component={Link}
      to={to}
      color="primary"
      rel="noopene|r noreferrer"
      variant="contained"
      sx={{ height: '30px', fontSize: '12px', padding: '0px', px: 1, py: 0 }}
    >
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
          textAlign: 'center',
        }}
      >
        {label}
      </Typography>
    </Button>
  </Tooltip>
);

LinkButton.propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  tooltipTitle: PropTypes.string,
  label: PropTypes.string.isRequired,
};

LinkButton.defaultProps = {
  tooltipTitle: '',
};

export default LinkButton;
